<template>
  <b-row v-if="maintenance">
    <b-col cols="12">
      <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
        <div>
          <h2 class="font-weight-bolder m-0 hGoBack">
            <span @click="$router.go(-1)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
            </span> Ficha del mantenimiento
          </h2>
        </div>
      </div>
      <!-- info -->
      <b-card>
        <div class="text-right">
          <span v-if="currentUser.roles[0].name === 'super_admin' && maintenance.status.id !== 7">
            <b-link
              :to="{ name: 'editMaintenance', params: { id: maintenance.id } }"
              class="btn-primary mr-1"
            >
              <span><feather-icon icon="Edit2Icon" /> Editar</span>
            </b-link>
          </span>

          <hr>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <h3 class="font-weixrght-bolder hTitle">
              {{ maintenance.name }}
            </h3>
          </div>
          <div>
            <b-badge
              :variant="statusMaintenanceVariants[maintenance.status.alias]"
              class="ml-2 position-relative"
              style="top: -3px"
            >
              {{ maintenance.status.name }}
            </b-badge>
          </div>
        </div>
        <hr>
        <b-row class="body-info">
          <b-col
            md="10"
            sm="12"
          >
            <b-row>
              <b-col
                md="4"
                sm="12"
              >
                <p><strong>ID:</strong> <small>{{ maintenance.id }}</small></p>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <p>
                  <strong>Ref:</strong> <small>{{ maintenance.ref }}</small>
                </p>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <p>
                  <strong>Fecha:</strong> <small>{{ dateColumn(maintenance.date) }}</small>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="4"
                sm="12"
              >
                <p>
                  <strong>Obra:</strong>
                  <small>
                    <b-link :to="{ name: 'viewProject', params: { id: maintenance.project.id } }"
                      class="link">
                      {{ maintenance.project.name }}
                    </b-link>
                  </small>
                </p>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <p>
                  <strong>Cliente:</strong>
                  <!-- <small>{{ maintenance.client.name }}</small> -->
                  <b-link :to="{ name: 'viewClient', params: { id: maintenance.client.id } }" class="link">
                    {{ maintenance.client.name }}
                  </b-link>
                </p>
              </b-col>
              <b-col
                v-if="maintenance.user"
                md="4"
                sm="12"
              >
                <p>
                  <strong>Responsable:</strong>
                  <b-link :to="{ name: 'viewUser', params: { id: maintenance.user.id } }" class="link">
                    {{ maintenance.user.name }}
                    {{ maintenance.user.surname }}
                  </b-link>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="maintenance.frequency_type"
                md="4"
                sm="12"
              >
                <p><strong>Frecuencia:</strong> <small>{{ frequencyText[maintenance.frequency_type] }}</small></p>
              </b-col>
              <b-col
                v-if="maintenance.description"
                md="12"
                sm="12"
              >
                <p class="m-0"><strong>Descripcion:</strong></p>
                <div v-html="maintenance.description"></div>
              </b-col>
              <b-col
                v-if="maintenance.documents && maintenance.documents.length > 0"
                md="4"
                sm="12"
              >
                <b-link
                  target="_blank"
                  :href="maintenance.documents[0].path"
                  class=""
                >
                  <feather-icon icon="FileIcon" />
                  Informe final
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <!-- tabs -->
      <b-tabs
        content-class="col-12 mt-1 mt-md-0"
        nav-wrapper-class="col-12"
        nav-class="nav-left"
      >
        <!--  assets -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="LayersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Resultados revisión equipos</span>
          </template>
          <b-card no-body>
            <!-- botones -->
            <div class="p-1">
              <div
                v-if="
                  currentUser.roles[0].name === 'super_admin' ||
                  (currentUser.roles[0].name === 'tec' && currentUser.id === maintenance.user.id)
                "
                class="d-flex align-items-center justify-content-end"
              >
                <!-- init review -->
                <span v-if="(maintenance.status.id === 5)">
                  <b-link
                    :disabled="initMaintenance"
                    @click="revisar()"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      class="mr-1 text-nowrap"
                    >
                      {{ 'Iniciar revisión' }}
                    </b-button>
                  </b-link>
                </span>
                <!-- add assets -->
                <span v-if="currentUser.roles[0].name === 'super_admin' && maintenance.status.id !== 7">
                  <b-link
                    :to="{ name: 'editMaintenance', params: maintenance.id }"
                    class="d-inline-block px-50 text-dark"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      class="text-nowrap"
                    >
                      + Añadir equipos a revisar
                    </b-button>
                  </b-link>
                </span>

                <span v-if="(revisado)">
                  <b-link @click="closeMaintenance()">
                    <b-button
                      variant="primary"
                      size="sm"
                      class="mr-1 text-nowrap"
                    >
                      Finalizar mantenimiento
                    </b-button>
                  </b-link>
                </span>
              </div>
            </div>
            <!-- tabla -->
            <b-table
              hover
              responsive
              :items="maintenance.assets_mantenimientos"
              :fields="columns"
              class="table-white"
            >
              <template #cell(asset)="data">
                <b-link
                  :to="{ name: 'viewAsset', params: { id: data.item.asset.id } }"
                  class="link"
                >
                  {{ data.item.asset.product.name }}
                </b-link>
              </template>
              <!-- dates -->
              <template #cell(last_review_date)="data">
                <span>{{ dateColumn(data.item.asset.last_review_date) }}</span>
              </template>
              <template #cell(next_review_date)="data">
                <span>{{ dateColumn(data.item.asset.next_review_date) }}</span>
              </template>
              <!-- status -->
              <template #cell(status)="data">
                <b-badge
                  class="d-flex justify-content-center align-items-center position-relative"
                  :variant="statusAssetVariants[data.item.asset.status.alias]"
                  style="top: -3px"
                >
                  {{ data.item.asset.status.name }}
                </b-badge>
              </template>
              <!-- acciones -->
              <template #cell(review)="data">
                <div v-if="(maintenance.status.alias === 'pending')">
                  <b-form-select
                    v-model="data.item.apto"
                    :options="aptoOptions"
                    @change="handleChangeApto(data.item.asset_id, data.item.apto)"
                    :disabled="['admin','tec_client'].includes(currentUser.roles[0].name)"
                  />
                </div>
                <div v-else-if="(maintenance.status.alias === 'complete' || maintenance.status.alias === 'unfavorable')">
                  <span v-if="data.item.apto">
                    OK
                  </span>
                  <span v-else>
                    No OK
                  </span>
                </div>
              </template>
              <!-- Documentos de revision -->
              <template #cell(review_documents)="data">
                <div class="d-flex justify-content-center">
                  <feather-icon
                    v-if="data.item.comment && data.item.documents.length > 0"
                    :id="`popover-target-${data.item.id}`"
                    class="mr-1"
                    icon="MessageSquareIcon"
                    size="25"
                  />
                  <b-popover
                    :target="`popover-target-${data.item.id}`"
                    triggers="hover"
                    placement="top"
                  >
                    <template #title>
                      Nota
                    </template>
                    {{ data.item.comment }}
                  </b-popover>
                  <b-link
                    v-if="!['admin','tec_client'].includes(currentUser.roles[0].name) && maintenance.status.id !== 7"
                    v-b-modal.modalDoc
                    class="mr-1"
                    @click="setModalData(data.item)"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      size="25"
                    />
                  </b-link>
                  <b-link
                    v-if="data.item.documents.length > 0 && validateDownloadDocuments"
                    :href="data.item.documents[0]['path']"
                    target="_blank"
                    download
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="25"
                    />
                  </b-link>
                </div>
              </template>
            </b-table>
            <!-- modal doc -->
            <b-modal
              id="modalDoc"
              title="Revision"
              hide-footer
              no-close-on-backdrop
            >
              <validation-observer ref="rewiewAsset">
                <b-form
                  class="form"
                  @submit.prevent="submitDoc"
                >
                  <input
                    ref="asset_id"
                    v-model="asset_id"
                    type="hidden"
                    value=""
                  >
                  <!-- comment -->
                  <b-form-textarea
                    id="textarea"
                    v-model="comment"
                    placeholder="Añadir comentarios"
                    rows="3"
                    max-rows="6"
                  />
                  <!-- docs -->
                  <b-form-group
                    :label="'Documentos'"
                    label-for="project-documents"
                  >
                    <ImageDropzone
                      v-if="docReady"
                      ref="documents"
                      :files="files"
                      max-files="1"
                    />
                  </b-form-group>
                  <!-- boton enviar form -->
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2 float-right"
                  >
                    Guardar
                  </b-button>
                </b-form>
              </validation-observer>
            </b-modal>
            <!-- modal incidencia equipo -->
            <b-modal
              id="modalIncidencia"
              title="Crear Incidencia"
              hide-footer
              no-close-on-backdrop
            >
              <validation-observer ref="createIncidence">
                <b-form
                  class="form"
                  @submit.prevent="submitIncidencia"
                >
                  <input
                    ref="id_asset"
                    v-model="id_asset"
                    type="hidden"
                    value=""
                  >

                  <!-- name -->
                  <validation-provider
                    #default="{ errors }"
                    :name="'Nombre'"
                    rules="required"
                  >
                    <b-form-group
                      :label="'Nombre'"
                      label-for="client-name"
                    >
                      <b-form-input
                        v-model="name"
                        name="name"
                        :placeholder="'Nombre'"
                      />
                    </b-form-group>
                    <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <!-- date -->
                  <b-form-group
                    :label="'Fecha'"
                    label-for="date"
                  >
                    <flat-pickr
                      v-model="datetime"
                      name="date"
                      class="form-control"
                    />
                  </b-form-group>

                  <!-- description -->
                  <b-form-group
                    :label="'Descripción'"
                    label-for="product-description"
                  >
                    <quill-editor v-model="description" />
                  </b-form-group>

                  <!-- docs -->
                  <b-form-group
                    :label="'Documentos'"
                    label-for="documentos"
                  >
                    <ImageDropzone
                      ref="documentos"
                      :files="files"
                      max-files="1"
                    />
                  </b-form-group>

                  <!-- boton enviar form -->
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2 mr-1 float-right"
                  >
                    Crear
                  </b-button>
                </b-form>
              </validation-observer>
            </b-modal>
            <!-- modal para finalizar mantenimiento -->
            <ModalCloseMaintenance />
          </b-card>
        </b-tab>
        <!-- incidences -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="AlertTriangleIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Incidencias asociadas</span>
          </template>
          <incidences-table
            :client_id="maintenance.client.id"
            :project_id="maintenance.project.id"
            :maintenance="maintenance"
          />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BLink, BButton, BFormRadio, BFormGroup, BForm, BFormInput, BContainer, BCardTitle, BBadge, BTable, BTabs, BTab, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
import { TimestampToFlatPickr, FlatPickrToTimestamp } from '@/libs/helpers'
import { quillEditor } from 'vue-quill-editor'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    vSelect,
    BLink,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BContainer,
    BFormRadio,
    BFormGroup,
    BForm,
    BFormInput,
    BFormSelect,
    BBadge,
    BTable,
    BTabs,
    BTab,
    BFormTextarea,
    ImageDropzone,
    quillEditor,
    flatPickr,
    IncidencesTable: () => import('./IncidencesTable.vue'),
    ModalCloseMaintenance: () => import('@/components/maintenances/modals/ModalCloseMaintenance.vue')
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      frequencyText: config.frequencyText,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      sortBy: '',
      sortDesc: '',
      columns: [
        {
          label: 'Nombre',
          key: 'asset',
        },
        {
          label: 'Cod. INI',
          key: 'asset.ini',
        },
        {
          label: 'Zona',
          key: 'asset.location.name',
        },
        {
          label: 'Último mantenimiento',
          key: 'last_review_date',
        },
        {
          label: 'Próximo mantenimiento',
          key: 'next_review_date',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Revisión',
          key: 'review',
        },
        {
          label: 'Revisión Documentos',
          key: 'review_documents',
        },
      ],
      initMaintenance: false,
      status: '', // status
      statusAssetVariants: config.statusAssetVariants,
      statusMaintenanceVariants: config.statusMaintenanceVariants,
      aptoOptions: [
        { value: null, text: 'Pendiente' },
        { value: 1, text: 'OK' },
        { value: 0, text: 'No OK' },
      ], // modal
      asset_id: '',
      comment: '',
      files: [],
      docReady: false,
      // form
      id_asset: '',
      name: '',
      datetime: '',
      description: '',
      revisado: false,
    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      maintenance: 'maintenances/getMaintenance',
      totalItems: 'maintenances/getTotalItems',
    }),
    validateDownloadDocuments () {
      if (this.currentUser.roles[0].name === 'admin') {
        return this.maintenance.status.alias === 'complete'
      }
      return true
    }
  },
  methods: {
    ...mapActions({
      getMaintenance: 'maintenances/getMaintenance',
      delete: 'addresses/delete',
      create: 'incidences/add',
      init: 'maintenances/init',
      close: 'maintenances/close',
      changeApto: 'maintenances/changeApto',
      review: 'maintenances/review',
      getIncidences: "incidences/getListIncidences",
    }),
    async checkAllOk() {
      let permit = false

      if (this.maintenance.status && this.maintenance.status.id === 6) {
        permit = true // esta en curso, pongo a true y valido

        /*this.maintenance.assets_mantenimientos.forEach((element, index) => {
          if (element.apto === 0) permit = false
        })*/
      } else {
        permit = false
      }

      this.revisado = permit
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    async setModalData(asset) {
      this.asset_id = asset.id
      this.comment = asset.comment
      this.files = []

      if (asset.documents.length > 0) {
        const aux = asset

        asset.documents.forEach((element, index) => {
          const file = {
            name: `${element.name}`,
            url: element.path,
            urlDelete: `/maintenances_assets/delete_doc/${aux.id}/${element.id}`,
          }
          this.files.push(file)
        })
      }

      this.docReady = true
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'modalIncidencia')
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modalIncidencia')
    },
    deleteAddress(id) {
      this.$bvModal
        .msgBoxConfirm('¿Estás seguro de eliminar la dirección?', {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    async handleChangeApto(asset, value) {
      const data = new FormData()

      data.append('asset_id', asset)
      data.append('apto', Boolean(value))

      await this.changeApto({
        id: this.maintenance.id,
        maintenance: data,
      })

      this.id_asset = asset
      if (value === 0) this.showModal()

      this.checkAllOk()
    },
    async revisar() {
      await this.init({
        id: this.maintenance.id,
      })
    },
    async closeMaintenance() {
      this.$root.$emit('bv::show::modal', 'modalCloseMaintenance')
      // await this.close({
      //   id: this.maintenance.id,
      // })
    },
    async submitDoc() {
      const formData = this.$refs.documents.getFormData('documents')

      if (!this.$refs.documents.getFiles().length) {
        formData.append('comment', '')
      } else {
        formData.append('comment', this.comment)
      }

      await this.review({
        id: this.asset_id,
        maintenance: formData,
      })

      this.comment = ''
      this.files = []
    },
    async submitIncidencia() {
      const data = this.$refs.documentos.getFormData('documents')

      data.append('name', this.name)
      data.append('description', this.description)
      data.append('maintenance_id', this.maintenance.id)
      data.append('asset_id', this.id_asset)

      if (this.datetime) {
        data.append('date', FlatPickrToTimestamp(this.datetime))
      }

      this.hideModal()

      await this.create({ incidence: data })

      this.name = ''
      this.description = ''
      this.date = ''
      this.files = []
    },
  },
  async created() {
    await this.getMaintenance(this.$route.params.id)
    await this.getIncidences({
      project: this.maintenance.project_id,
      maintenance_id: this.maintenance.id,
      page: 1,
      per_page: 10,
      search: '',
    });
    await this.checkAllOk()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.close {
  transform: none !important;
  background: none !important;
  box-shadow: none !important;
}
</style>
